import { render, staticRenderFns } from "./PurchasesTop.vue?vue&type=template&id=9105c86e&lang=pug&"
import script from "./PurchasesTop.vue?vue&type=script&lang=js&"
export * from "./PurchasesTop.vue?vue&type=script&lang=js&"
import style0 from "./PurchasesTop.vue?vue&type=style&index=0&lang=stylus&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VLayout,VSelect,VTextField})

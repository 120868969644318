import { render, staticRenderFns } from "./PurchasesTable.vue?vue&type=template&id=7b32bf08&lang=pug&"
import script from "./PurchasesTable.vue?vue&type=script&lang=js&"
export * from "./PurchasesTable.vue?vue&type=script&lang=js&"
import style0 from "./PurchasesTable.vue?vue&type=style&index=0&lang=stylus&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
